<template>

  <v-dialog
    v-model="dialogCredit"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- <div class="d-flex justify-space-between"> -->
      <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
      <v-btn
        color="primary"
        dark
        class="mb-2 mr-2"
        v-bind="attrs"
        v-on="on"
        @click="close"
      >
        เติมเครดิต
      </v-btn>
      <!-- </div> -->
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formCredit }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              v-if="!this.$route.params.id"
            >
              <v-select
                label="ลูกค้า"
                v-model="editedItem.customers_id"
                :items="customers"
                dense
                item-text="member_name"
                item-value="member_id"
              >
              </v-select>
              <small
                class="validate-err"
                v-for="(err,index) in v$.editedItem.customers_id.$silentErrors"
                v-if="v$.editedItem.customers_id.$error">{{ index > 0 ? ', ' : '' }}
                {{ err.$message }}</small>
            </v-col>


            <v-col
              cols="12"
              sm="12"
              md="12"
            >

              <v-select
                label="ประเภทแพ็กเกจ"
                v-model="editedItem.product_type_id"
                :items="type_packages"
                item-text="product_type_name"
                item-value="product_type_id"
              >
              </v-select>
              <small
                class="validate-err"
                v-for="(err,index) in v$.editedItem.product_type_id.$silentErrors"
                v-if="v$.editedItem.product_type_id.$error">{{ index > 0 ? ', ' : '' }}
                {{ err.$message }}</small>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                type="number"
                v-model="editedItem.amount_credit"
                label="จำนวนเครดิต"
              ></v-text-field>
              <small
                class="validate-err"
                v-for="(err,index) in v$.editedItem.amount_credit.$silentErrors"
                v-if="v$.editedItem.amount_credit.$error">{{ index > 0 ? ', ' : '' }}
                {{ err.$message }}</small>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="editedItem.note"
                label="หมายเหตุ"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import instance_credit_customer from '../../../services/creditCustomer'
import Swal from "sweetalert2";
import { minLength, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  props: ['customer_id'],
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data (){
    return {
      dialogCredit: false,
      editedIndex : -1,
      customers : [],
      type_packages : [],
      editedItem: {
        customers_id : "",
        product_type_id : "",
        amount_credit : 0,
        note : "",
      },
    }
  },
  validations() {
    var valid =   {
      editedItem: {
        customers_id: {  },
        product_type_id: {required },
        amount_credit: {required },
      }
    }
    if (!this.$route.params.id){
      valid.editedItem.customers_id = {required};
    }

    return valid
  },

  methods: {
    initCustomer(){
      this.$store.state.isLoading = true
      instance_credit_customer.listMember().then(res => {
        this.customers = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
    initTypePackage(){
      this.$store.state.isLoading = true
      instance_credit_customer.listTypePackage().then(res => {
        this.type_packages = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      });
    },
    close() {
      this.v$.$reset();
      this.dialogCredit = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    async save() {
      if (this.$route.params.id) {
        this.editedItem.customers_id = this.$route.params.id
      }
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      this.$store.state.isLoading = true

      instance_credit_customer.creditStore(this.editedItem).then(res => {

        setTimeout(function () {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
        });
        this.close();
        this.$store.state.isLoading = false
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      })
    }
  },
  computed: {
    formCredit() {
      return this.editedIndex === -1 ? ' เติมเครดิต' : 'แก้ไข กลุ่มพนักงาน'
    },
    formCash() {
      return this.editedIndex === -1 ? ' เติมเงิน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },
  mounted() {
    this.initCustomer();
    this.initTypePackage();
  }
}
</script>

<style scoped>
.padding__content {
  padding: 5rem 0 !important;
}
.padding__content a {
  text-decoration: none;
}
</style>
